<template>
  <div
    class="bg-[#232323] h-full pb-10 w-screen max-w-screen min-w-screen text-white background relative overflow-x-hidden">
    <div class="z-[0]   w-full fixed">
      <img src="../assets/trees.png"
        class="absolute left-10  md:left-[50vw] lg:top-20 top-0 filter saturate-0 object-cover z-[-1] blur-[1.5px] max-w-full">
      <img src="../assets/trees.png"
        class="sm:hidden md:hidden absolute -left-36  lg:block scale-x-[-1] lg:top-0 top-0 filter saturate-0 object-cover z-[-1] blur-[1.5px] max-w-full">


      <div class="hidden">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-56 filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full ">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-[600px] filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -right-10 top-[550px] filter saturate-0 object-cover z-[-1] blur-[5px] scale-x-[-1]  max-w-full ">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-[1000px] filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -right-10 top-[1050px] filter saturate-0 object-cover z-[-1] blur-[5px] scale-x-[-1]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-[1200px] filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -right-10 top-[1250px] filter saturate-0 object-cover z-[-1] blur-[5px] scale-x-[-1]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-[1500px] filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -right-10 top-[1550px] filter saturate-0 object-cover z-[-1] blur-[5px] scale-x-[-1]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-[1850px] filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -right-10 top-[1850px] filter saturate-0 object-cover z-[-1] blur-[5px] scale-x-[-1]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -left-24 top-[2150px] filter saturate-0 object-cover z-[-1] blur-[5px]  max-w-full">
        <img src="../assets/trees3.png"
          class="absolute -right-10 top-[2150px] filter saturate-0 object-cover z-[-1] blur-[5px] scale-x-[-1]  max-w-full ">
      </div>



    </div>


    <div class="overflow-x-hidden  relative max-w-[800px] mx-auto ">
      <section class="flex justify-between max-w-[449px] mx-auto animhidden">
        <div>
          <div class="w-40 h-40 rounded-full overflow-hidden bg-[#232323] border-neutral-900 border-2 mt-10 ml-4 mr-2 delay-75 animhidden">
            <img src="../assets/photo.png" class="scale-150 mb-10 -mt-5 mx-auto grayscale filter contrast-0 hover:contrast-100 transition-all duration-1000">
          </div>
          <div class="flex justify-center text-neutral-300 my-2 font-bold">Contact Me :</div>
          <div class="flex justify-center space-x-2">


            <a href="https://www.linkedin.com/in/prithak-lamsal-b131bb286/" target="_blank"
              class="w-8 h-8 rounded-full bg-neutral-200 overflow-hidden  p-0 cursor-pointer z-[100] animlogo animhidden hover:animate-bounce">
              <img src="../assets/language logo/linkedin-icon.svg" class=" scale-100 cursor-pointer">
            </a>




            <a href="https://github.com/sasquatchrex" target="_blank"
              class="w-8 h-8 rounded-full bg-neutral-200  overflow-hidden p-0 animlogo animhidden hover:animate-bounce">
              <img src="../assets/language logo/github-icon-1.svg" class=" scale-100">
            </a>


            <a href="https://twitter.com/RexSasquatch" target="_blank"
              class="w-8 h-8 rounded-full bg-neutral-200   overflow-hidden p-0 animlogo animhidden hover:animate-bounce">
              <img src="../assets/language logo/x-2.svg" class=" scale-75 mt-0.5">

            </a>


          </div>
        </div>
        <div class="mt-16 w-64  ">
          <div class="min-h-[150px] w-full">
            <div class="text-[#D0CFCF]">Hey! Its me</div>
            <div class="text-3xl mt-1 font-semibold  animhidden">Prithak lamsal</div>
            <div class="text-[#D2CECE] text-base my-3 animhidden">A full-stack python developer based on Kathmandu, Nepal</div>
          </div>

          <div class="flex justify-left mt-4  ">
            <button class="px-4 py-2 bg-green-400 rounded-2xl flex animate-pulse">
              <img src="../assets/language logo/download.svg" class="mr-2 w-6 ">
              <div class="text-black">Resume</div>
            </button>
          </div>
        </div>
      </section>

      <section class="mt-5 mx-auto  ">
        <div class="text-center text-xl font-medium animhidden">Tech Stacks</div>
        <div class="flex flex-row space-x-2 justify-center mt-2 animlogos">
          <div class="w-12 h-12 rounded-full bg-neutral-700 overflow-hidden  transition-all    animlogo animhidden">
            <img src="../assets/language logo/python-5.svg" class="scale-50">
          </div>
          <div class="w-12 h-12 rounded-full bg-neutral-700 overflow-hidden   animlogo animhidden">
            <img src="../assets/language logo/vue-9.svg" class="scale-50 mt-1">
          </div>
          <div class="w-12 h-12 rounded-full bg-neutral-700 overflow-hidden   animlogo animhidden">
            <img src="../assets/language logo/flutter.svg" class="scale-50 -mt-2 -ml-0.5">
          </div>
          <div class="w-12 h-12 rounded-full bg-neutral-700 overflow-hidden   animlogo animhidden">
            <img src="../assets/language logo/tailwind-css-2.svg" class="scale-50 mt-2">
          </div>
          <div class="w-12 h-12 rounded-full bg-neutral-700 overflow-hidden   animlogo animhidden">
            <img src="../assets/language logo/javascript-1.svg" class="scale-50 -mt-0.5">
          </div>
          <div class="w-12 h-12 rounded-full bg-neutral-700 overflow-hidden   animlogo animhidden">
            <img src="../assets/language logo/c-1.svg" class="scale-50 -mt-1 ml-0.5">
          </div>
        </div>
      </section>

      <section class="h-full bg-[#333333] w-[85%] mx-auto mt-10 rounded-2xl pb-5 animhidden">
        <div class="text-center text-xl font-semibold py-2">About Me</div>
        <p class="text-[#CFCFCF] pl-6 pr-4 text-[0.89rem]">
          I am a proficient Python developer, specializing particularly in Django for web development. Additionally, I
          possess expertise as a Vue.js developer, focusing on frontend development. Residing in Kathmandu, Nepal, I am
          concurrently pursuing my studies. Furthermore, I am an ardent enthusiast of computer science and a part-time
          gamer. Additionally, I have also ventured into mobile application development, utilizing Flutter to create
          engaging and responsive applications.
        </p>
      </section>

      <section class="flex justify-center my-5 space-x-1 animhidden">
        <div class="w-2 h-2 rounded-full bg-neutral-300"></div>
        <div class="w-2 h-2 rounded-full bg-neutral-300"></div>
        <div class="w-2 h-2 rounded-full bg-neutral-300"></div>
      </section>

      <section class="text-xl font-medium text-center my-8 animhidden">
        My Projects
      </section>

      <section class="h-full bg-[#333333] w-[85%] mx-auto mt-5 rounded-2xl pb-5 pt-5 animhidden">
        <div class="bg-neutral-700 ml-6 mr-4 h-36 md:h-52 rounded-xl mx-auto overflow-hidden">
          <img src="../assets/Projects/Investex.png" class="imageanimhidden grayscale filter blur-sm hover:blur-none hover:grayscale-0 mt-5 scale-150 md:mt-0 md:scale-100">
        </div>
        <div class="flex justify-between mr-4">
          <div class="pl-6 text-left text-2xl font-semibold py-2">Investex</div>
          <div class="pl-6 text-right text-base mt-1.5 font-semibold py-2 text-neutral-400">December, 2023</div>

        </div>
        <p class="text-[#CFCFCF] pl-6 pr-4 text-[0.89rem]">
          Investex stands as a comprehensive portfolio management application with a primary focus on providing
          real-time insights into the stock market landscape of Nepal, while seamlessly integrating portfolio management
          functionalities. While this project remains a work in progress, it represents one of my most significant and
          meticulously developed endeavors to date.
        </p>
        <div class="ml-6 mr-4 mt-4 flex justify-between items-center">
          <div class="flex">
            <div class="mt-1 mr-2">Techs used :</div>
            <div class="flex flex-row space-x-2 justify-center">
              <div class="w-8 h-8 rounded-full bg-neutral-700 overflow-hidden backdrop-blur-md">
                <img src="../assets/language logo/python-5.svg" class="scale-50">
              </div>
              <div class="w-8 h-8 rounded-full bg-neutral-700 overflow-hidden backdrop-blur-md">
                <img src="../assets/language logo/flutter.svg" class="scale-50 -mt-1">
              </div>
            </div>
          </div>
          <a href="https://github.com/sasquatchrex" target="_blank">
          <button class="bg-green-400 rounded-xl px-4 py-2 flex hover:bg-green-500 transition-all">
            <img src="../assets/language logo/github-icon-1.svg" class="w-4 h-4 mt-1 mr-2">
            <div class="text-black">Github</div>
          </button>
        </a>
        </div>
      </section>

      <section class="h-full bg-[#333333] w-[85%] mx-auto mt-5 rounded-2xl pb-5 pt-5 animhidden">
        <div class="bg-neutral-700 ml-6 mr-4 h-36 md:h-52 rounded-xl mx-auto overflow-hidden">
          <img src="../assets/Projects/Messegex.png" class="imageanimhidden grayscale filter blur-sm mt-5 scale-150 hover:blur-none ml-[4.5rem] hover:grayscale-0 md:scale-100 md:mt-0 md:ml-0">
        </div>
        <div class="flex justify-between mr-4">
          <div class="pl-6 text-left text-2xl font-semibold py-2">Messegex</div>
          <div class="pl-6 text-right text-base mt-1.5 font-semibold py-2 text-neutral-400">October, 2023</div>

        </div>

        <p class="text-[#CFCFCF] pl-6 pr-4 text-[0.89rem]">
          Messegex represents one of my initial projects, developed utilizing Capacitor technology. As an online
          real-time chat application meticulously crafted for mobile platforms, Messegex showcases my early foray into
          application development. Its focus on facilitating seamless communication in real-time underscores its
          significance as a foundational project in my portfolio.
        </p>
        <div class="ml-6 mr-4 mt-4 flex justify-between items-center">
          <div class="flex">
            <div class="mt-1 mr-2">Techs used :</div>
            <div class="flex flex-row space-x-2 justify-center">
              <div class="w-8 h-8 rounded-full bg-neutral-700 overflow-hidden backdrop-blur-md">
                <img src="../assets/language logo/vue-9.svg" class="scale-50 mt-1">
              </div>
              <div class="w-8 h-8 rounded-full bg-neutral-700 overflow-hidden backdrop-blur-md">
                <img src="../assets/language logo/firebase-1.svg" class="scale-50 -mt-1 p-1">
              </div>
            </div>
          </div>
          <a href="https://github.com/sasquatchrex" target="_blank">
          <button class="bg-green-400 rounded-xl px-4 py-2 flex hover:bg-green-500 transition-all">
            <img src="../assets/language logo/github-icon-1.svg" class="w-4 h-4 mt-1 mr-2">
            <div class="text-black">Github</div>
          </button>
        </a>
        </div>
      </section>
      <section class="h-full bg-[#333333] w-[85%] mx-auto mt-5 rounded-2xl pb-5 pt-5 animhidden">
        <div class="bg-neutral-700 ml-6 mr-4 h-36 md:h-52 rounded-xl mx-auto overflow-hidden  ">
          <img src="../assets/Projects/Cubics.png" class="imageanimhidden grayscale filter blur-sm mt-5 scale-150 hover:blur-none ml-[4.5rem] hover:grayscale-0 md:scale-100 md:mt-0 md:ml-0">
        </div>
        <div class="flex justify-between mr-4">
          <div class="pl-6 text-left text-2xl font-semibold py-2">Cubics</div>
          <div class="pl-6 text-right text-base mt-1.5 font-semibold py-2 text-neutral-400">Feburary, 2022</div>

        </div>

        <p class="text-[#CFCFCF] pl-6 pr-4 text-[0.89rem]">
          Cubics is a simple game developed using Unity as the engine and C# as the programming language. It marks my
          first project in Unity and C#. Via this project, I learnet different aspects of Game Development which
          motivated me to create more of those.
        </p>
        <div class="ml-6 mr-4 mt-4 flex justify-between items-center">
          <div class="flex">
            <div class="mt-1 mr-2">Techs used :</div>
            <div class="flex flex-row space-x-2 justify-center">
              <div class="w-8 h-8 rounded-full bg-neutral-700 overflow-hidden backdrop-blur-md">
                <img src="../assets/language logo/vue-9.svg" class="scale-50 mt-1">
              </div>
              <div class="w-8 h-8 rounded-full bg-neutral-700 overflow-hidden backdrop-blur-md">
                <img src="../assets/language logo/firebase-1.svg" class="scale-50 -mt-1 p-1">
              </div>
            </div>
          </div>
          <a href="https://github.com/sasquatchrex" target="_blank">
            <button class="bg-green-400 rounded-xl px-4 py-2 flex hover:bg-green-500 transition-all">
            <img src="../assets/language logo/github-icon-1.svg" class="w-4 h-4 mt-1 mr-2 ">
            <div class="text-black">Github</div>
          </button>
          </a>
          
        </div>
      </section>
    </div>
  </div>

</template>

<script>
  


export default {
  name: 'HelloWorld',

  mounted(){
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(entry);
          if (entry.isIntersecting) {
            entry.target.classList.add("animshow");
          } else {
            // entry.target.classList.remove("animshow");
          }
        });
      });
    const observer1 = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(entry);
          if (entry.isIntersecting) {
            entry.target.classList.add("imageanimshow");
          } else {
            // entry.target.classList.remove("animshow");
          }
        });
      });

      const hiddenElements = document.querySelectorAll(".animhidden");
      const hiddenElements1 = document.querySelectorAll(".imageanimhidden");
      hiddenElements.forEach((el) => observer.observe(el));
      hiddenElements1.forEach((el) => observer1.observe(el));

  }


}
</script>

<style>
/* .background{
  background-image: url("../assets/trees.png");
  
} */

html,
body {
  overflow-x: hidden;
}


.animhidden{
  opacity: 0;
  /* filter : blur(5px); */
  transform: translateX(-100%);
  transition: all 1s;

}
.imageanimhidden{
  opacity: 0;
  filter : blur(5px);
  /* transform: scale(0); */
  transition: all 1.5s;

}

.animshow{
  transition-delay: 400ms;
  opacity: 1;
  /* filter: blur(0); */
  transform: translateX(0);
  transition: all 1s;
}
.imageanimshow{
  transition-delay: 400ms;
  opacity: 1;
  filter: blur(0);
  /* transform: scale(100%); */
  transition: all 1s;
}

.animlogo:nth-child(2){
  transition-delay: 200ms;
}
.animlogo:nth-child(3){
  transition-delay: 400ms;
}
.animlogo:nth-child(4){
  transition-delay: 600ms;
}
.animlogo:nth-child(5){
  transition-delay: 800ms;
}
.animlogo:nth-child(6){
  transition-delay: 1000ms;
}

</style>