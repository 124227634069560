<template>
  <HelloWorld/>
   <!-- <gptView/> -->
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
// import gptView from './components/gpt.vue'

export default {
  name: 'App',
  components: {
    // gptView,
    HelloWorld
  }
}
</script>


